.wrapper-ourStory {
    margin-top: 5rem;
    background-color:#f5f5f5;
    margin: 0;
    .header-ourStory {
        margin-top: 5rem;
        margin-bottom: 5rem;
        text-align: left;
        color: #55509f;
    }
    .pb-text {
        margin-bottom: 5rem;
    }
    .wrapper-text {
        width: 1050px;
        margin: auto;
    }
}
