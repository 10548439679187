.wrapper-members{
    background-color:#f5f5f5;
  margin: 0;
    .container-members{
        width: 1050px;
        margin: auto;
        h6 {
            font-size: 20px;
            font-weight: 700;
            color: #56509F;
        }
        .icons {
            width: 300px;
            height: 300px;
        }
    }
}