.wrapper-partners{
    background-color:
    #f5f5f5;
    .title-partners {
        color: #55509f;
    }
    .wrapp{
        max-width: 1050px !important;
        margin: auto;
        margin-top: 5rem;
        img{
            height: 100px;
            width: 120px;
            margin-bottom: 5rem;
        }
        #crypto{
            margin-top: 1.7rem;
            height: 50px;
        }
    }

}

@media only screen and (max-width: 600px) {
    .wrapp {
        img {
            height: 500px;
                width: 70px;
                margin-bottom: 5rem;
        }
    }
}
