.template-component-social-icon {
    height: 5rem;
    margin-top: 2rem;
    .icons {
        margin: 0.2rem;
        height: 3rem;
        width: 3rem;

        background-color: orange;
        color: white;
    }
    .copyright {
        margin-top: 20rem;
    }
}
