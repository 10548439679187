.contact{
    background-color: #5f83cd;
    color: #FFFFFF;
    
    .contact-text{
        width: 1050px;
        margin: auto;
        display: flex;
        align-items: center;
        
    }
}