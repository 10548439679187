.wrapper {
    max-width: 1050px;
    margin: auto;
    box-sizing: border-box;
    padding: 0;

    .circle {
        height: 180px;
        width: 180px;
        border-radius: 50%;
        background: #fe6500;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }
    .circle-blue {
        height: 180px;
        width: 180px;
        border-radius: 50%;
        background: #5f83cd;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }

    .icons {
        color: white;
        font-size: 70px;
    }
    .info {
        max-width: 100%;
        height: 490px;
        //min-height: 490px;
    }
    .modal__close {
        border: 0;
        position: absolute;
        right: 15px;
        top: 15px;
        background: #fe6500;
        border-radius: 50%;
        color: #fff;
        padding: 5px 10px;
        cursor: pointer;
    }
    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #56509f;
        margin-bottom: 11px;
    }
    .list-checked ul li {
        padding-left: 30px;
        margin-bottom: 8px;
        background-size: 17px 17px;
        background-repeat: no-repeat;
        background-position: left 2px;
        background-image: url(style_1.png);
        list-style-type: none;
    }
}

.card-icon {
    width: 180px;
    height: 100px;
}

.in {
    height: 250px;
}
