.wrapper-letsMeet{
    max-width: 1050px !important;
    margin: auto;
    .title-letsMeet{
        margin-top: 2rem;
        margin-bottom: 5rem;
        color: #55509f;
    }
    .icons{
        height: 35px;
        width: 35px;
        color: #fe6500;
    }
}