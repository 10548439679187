.card {
    max-width: 100%;
    max-height: 100%;
    min-height: 700px;
    padding: 20px;
    border: solid 4px #fe660059;
    .card-img-top {
        //margin-left: 25%;
        width: 80px;
        border-radius: 50%;
        border: 5px solid #56509f;
        height: 80px;
        -o-object-fit: cover;
        object-fit: cover;
    }
}
.btn-edit {
    border: none;
    background-color: #5f83cd;
    .icon-edit {
        color: white;
    }
}
.btn-delete {
    border: none;
    background-color: #d2392a;
    .icon-delete {
        color: white;
    }
}

.btn-delete:hover {
    background-color: #b13125;
}

.btn-edit:hover {
    background-color: #5170ad;
}
.card-update {
    .btn-edit {
        border: none;
        background-color: #5f83cd;
        .icon-edit {
            color: white;
        }
    }
    .btn-delete {
        border: none;
        background-color: #d2392a;
        .icon-delete {
            color: white;
        }
    }

    .btn-delete:hover {
        background-color: #b13125;
    }

    .btn-edit:hover {
        background-color: #5170ad;
    }
}
.createBtn {
    margin-left: auto;
}

.team-wrapper {
    margin-left: 260px;

    min-height: 100vh;
    height: 100%;
    width: fit-content;
    .col {
        width: fit-content;
    }
}
