.wrapper-donate {
    background-color: #fe6500;
    height: 100%;
    ul {
        width: 1050px;
        margin: auto;
        list-style-type: none;

        .icons {
            color: #fe6500;
            font-size: 70px;
        }
        li {
            color: white;
        }
        a {
            text-decoration: none;
            color: white;
        }
    }
    .circle {
        height: 180px;
        width: 180px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }
    // .item{
    //     max-width: 500px;
    //     height: 350px;
    //     padding: 2rem;
    // }
}
