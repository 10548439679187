.wrapper-members{
    background-color:#f5f5f5;
    width: 100%;
    .h1-heading {
        color: #56509F;
        font-size: 28px;
        margin-top: 4rem;
    }
    h6 {
        font-size: 20px;
        font-weight: 700;
        color: #56509F;
    }
    .title{
        width: 1050px;
        margin: auto;
    }
    .container-members{
        width: 1050px;
        margin: auto;
        .icons {
            width: 300px;
            height: 300px;
        }
    }
}