.wrapper-members{
    background-color:#f5f5f5;
    width: 100%;
    .container-members{
        width: 1050px;
        margin: auto;
        .icons {
            width: 300px;
            height: 300px;
        }
    }
}