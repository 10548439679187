.carousel{
    max-width: 100%;
    max-height: 500px;
    background-color: rgb(92, 90, 90);
    color: #ffbc3f;
    
    // .carousel-control-next-icon{
    //     color: rgb(251, 119, 31);
    //     height: 100%;
    // }

    .circle{
        background-color: #fff;
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
    }
    .carousel-control-prev-icon {
        height: 100%;
        // margin-right: 0.2rem;
        // margin-left: 0rem;
        // margin-top: 1rem;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffbc3f' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;

       }
       
       .carousel-control-next-icon {
           height: 100%;
        //    margin-right: 0rem;
        //    margin-left: 0.2rem;
        //    margin-top: 1rem;
         background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffbc3f' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
       }
    // img{
  
    //     height: 350px;
    //   }
    //   .carousel-wrapper {
    //     height: 250px;
    // }
}

// .carousel-img{
//     min-height:37rem ;
// }