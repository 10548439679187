.articles-wrapper {
    max-width: 1050px;
    margin: auto;
    box-sizing: border-box;
    .card {
        border: none;
        border-radius: 0%;
        padding: 2rem;
        .card-body {
            margin: 0;
            padding: 0;
        }
        .card-img-top {
            max-width: 350px;
            height: 170px;
        }
    }
}
