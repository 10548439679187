.loading-wrapper {
    animation: pulse 2s infinite;
}
@keyframes pulse {
    0% {
        transform: scale(0.9);
    }
    70% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.9);
    }
}
